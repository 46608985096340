<template>
  <div class="wewview">
    <div class="webview">
      <div class="webTab">
        <div class="header-back" @touchend="handleBack">
          <van-icon name="arrow-left" color="#fff" size="0.36rem" />
        </div>
        <div class="header-title">{{ title ? title : '极风分期' }}</div>
        <div class="header-right">
        </div>
      </div>
      <div class="webview-iframe">
        <iframe :src="linkUrl" class="webview-iframe-content" frameborder="0" scrolling="auto"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'thirdWebview',
  data () {
    return {
      linkUrl: '',
      title: ''
    }
  },
  mounted () {
    if (this.$route.query.linkUrl) {
      this.linkUrl = this.$route.query.linkUrl
      this.title = this.$route.query.name
    }

    // const _self = this
    // window.addEventListener('message', (event) => {
    //   console.log(event)
    //   if (event && event.data) {
    //     const data = JSON.parse(event.data)
    //     if (data.cmd === 'thirdurl') {
    //       _self.linkUrl = data.data
    //     }
    //   }
    // }, false)
  },
  methods: {
    handleBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.wewview {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #f3f3f3;

  .webview {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;

    .webTab {
      width: 100%;
      height: 0.92rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      line-height: 0.92rem;
      padding: 0 0.3rem;

      .header-back {
        width: 20%;
        text-align: left;

        i {
          font-size: 0.36rem;
          color: #000 !important;
          line-height: 0.9rem;
        }
      }

      .header-title {
        width: 60%;
        font-size: 0.4rem;
        color: #111;
        line-height: 0.9rem;
        text-align: center;
      }

      .header-right {
        width: 20%;
      }
    }

    .webview-iframe {
      width: 100vw;
      height: calc(100vh - 0.92rem);
      box-sizing: border-box;
      overflow: hidden;

      .webview-iframe-content {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
      }

      .webview-iframe-content::-webkit-scrollbar {
        display: none;
      }
    }

    .webview-iframe::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
